import React from "react";
import { getHotAndLimitedProducts } from "../../../utils/products";
import { Link } from "react-router-dom";
import { AsyncImage } from "loadable-image";
import { Blur } from "transitions-kit";
import { toClassNames } from "../../../utils/strings";
import RatingStarIcon from "../../../icons/RatingStarIcon";
import "./mobileProductList.scss";
import Pagination from "../Pagination";

const DEFAULT_PAGE_SIZE = 20;

export const MobileProductList = ({
  products,
  totalResults,
  currentPage = 0,
  setCurrentPage = null,
  enablePagination = true
}) => {
  const { hotProductSkus, limitedProductSkus } =
    getHotAndLimitedProducts(products);
  return (
    <div className="mobile-products-group-body">
      <div className="product-group-body-list">
        {products.map(
          (
            {
              discount,
              imageUrl,
              name,
              sku,
              price,
              listPrice,
              rating,
              ratingCount,
            },
            idx,
          ) => (
            <Link
              key={idx}
              className="product-group-body-grid-item"
              to={`/product/${sku}`}
            >
              <div className="product-group-body-grid-item-image-container">
                <AsyncImage
                  src={imageUrl}
                  style={{
                    width: "180px",
                    height: "180px",
                    borderRadius: 0,
                    backgroundColor: "#f5f5f5",
                  }}
                  Transition={Blur}
                  loader={<div style={{ background: "#888" }} />}
                />
              </div>
              <div className="product-group-item-status-container">
                <div className="product-group-item-discount">
                  <span>{discount}% OFF</span>
                </div>
                <div
                  className={toClassNames([
                    "product-group-item-status",
                    hotProductSkus.includes(sku)
                      ? "product-group-item-hot"
                      : limitedProductSkus.includes(sku)
                      ? "product-group-item-limited"
                      : "product-group-item-label-hidden",
                  ])}
                >
                  <span>
                    {hotProductSkus.includes(sku)
                      ? "HOT!"
                      : limitedProductSkus.includes(sku)
                      ? "LIMITED"
                      : ""}
                  </span>
                </div>
              </div>
              <div className="product-group-item-product-name">
                <span>{name}</span>
              </div>
              <div className="product-group-item-price-and-rating">
                <div className="product-group-item-product-price">
                  ${(Math.round(price * 100) / 100).toFixed(2)}
                </div>
                <span className="product-group-item-list-price">
                  ${(Math.round(listPrice * 100) / 100).toFixed(2)}
                </span>
                <div className="product-group-item-rating">
                  <RatingStarIcon
                    width={20}
                    height={20}
                    className={"product-group-item-rating-icon"}
                  />
                  <span className="product-group-item-rating-number">
                    {rating.toFixed(1)}
                  </span>
                  <span className="product-group-item-rating-count">
                    ({ratingCount})
                  </span>
                </div>
              </div>
            </Link>
          ),
        )}
      </div>
      { enablePagination && (
        <div className="product-group-pagination">
          <Pagination
            className="product-group-pagination-bar"
            currentPage={currentPage}
            totalCount={
              totalResults === 0 ? 0 : totalResults > 9980 ? 9980 : totalResults
            }
            pageSize={DEFAULT_PAGE_SIZE}
            siblingCount={0}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    
    </div>
  );
};
