import React, { useEffect, useState } from "react";
import { MobileHeader } from "../../header/MobileHeader";
import { MobileFooter } from "../../footer/MobileFooter";
import { CategoryOverlay } from "../../header/CategoryOverlay";
import "./mobilePrivacyPolicyPage.scss";
import MobileLoginModalOverlay from "../../header/MobileLoginModalOverlay";

export const MobilePrivacyPolicyPage = () => {
  const [isCategoryOverlayOpen, setIsCategoryOverlayOpen] = useState(false);
  const [isLoginModalOverlayOpen, setIsLoginOverlayOpen] = useState(false);

  const toggleCategoryOverlay = () => {
    setIsCategoryOverlayOpen(!isCategoryOverlayOpen); // Toggle overlay visibility
  };

  const toggleLoginModalOverlay = () => {
    setIsLoginOverlayOpen(!isLoginModalOverlayOpen);
  };

  // Toggle body class to disable or enable scrolling
  if (isCategoryOverlayOpen || isLoginModalOverlayOpen) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <div className="App">
      <div className="mobile-private-policy-page-container">
        <MobileHeader
          toggleCategoryOverlay={toggleCategoryOverlay}
          toggleLoginOverlay={toggleLoginModalOverlay}
        />
        <div className="mobile-privacy-policy-container">
          <h1>Privacy</h1>
          <div className="privacy-content">
            Explains what information we collect and why, how we use it, and how
            to review and update it.
          </div>
          <ul>
            <h2 className="privacy-title">General</h2>
            <li>
              savvydeal.ai (“we”, “our”, “us”) is committed to the protection of
              personal information provided by the users (“you”, “your”, “user”)
              your agreement implies your consent to the collection, retention
              and use of your personal information in accordance with the terms
              of this privacy policy.
            </li>
            <li>
              We take the privacy of our users seriously. We are committed to
              safeguarding the privacy of our users while providing a
              personalized and valuable service.
            </li>
            <li>
              When you use the website, the website may collect your device
              number and other personal information. A high standard of security
              is maintained by us for our users. However, the transmission of
              information via the Internet or telephone networks is not
              completely secure. While we do our best to protect your
              information, particularly with respect to protection of your
              personal data, savvydeal.ai cannot ensure the security of your
              data transmitted via the Internet, telephone or any other
              networks.
            </li>
            <li>
              Access to the contents available through the website is
              conditional upon your acceptance of this privacy policy which
              should be read together with the Terms of Service.
            </li>
            <li>
              If You do not agree to any of the provisions of this privacy
              policy or terms of service, You should not use the website. We may
              revise, alter, add, amend or modify this privacy policy at any
              time by updating this privacy policy. By browsing, accessing
              and/or using this website, you agree to be bound by any such
              alteration, amendment, addition or modification.
            </li>
          </ul>
          <ul>
            <h2 className="privacy-title">Information Collected</h2>
            <li>
              Information you provide us: in the course of using the website, we
              may ask you to provide us with certain information, including
              contact information, such as your name or e-mail address, unique
              identifiers, such as your user ID, or similar information.
            </li>
            <li>
              Site usage information: we may receive and store certain types of
              internet usage information whenever you visit the website, such as
              your click-stream data and your IP address. See “IP addresses and
              click stream data” section below.
            </li>
            <li>
              E-mail communications: when you open e-mails from us, we may
              receive a confirmation noting you opened the e-mail, if your
              computer supports such capabilities. We also may keep track of
              what e-mails we send you and what e-mails you may be receiving
              from our subsidiaries and affiliates. Additionally, we keep a
              record of what communications you have opted to receive or not to
              receive.
            </li>
          </ul>
          <ul>
            <h2 className="privacy-title">How Information May Be Collected</h2>
            <li>
              Registration: you may be asked to complete a registration form and
              provide personally identifiable information; you may also be asked
              to choose a user ID and password in order to take advantage of
              certain features the website may choose to offer, such as
              newsletters or contests. Participation in registration is
              voluntary and you will have the opportunity to decide whether or
              not to disclose information to the website.
            </li>
            <li>
              IP addresses and click-stream data: we may collect IP addresses
              and/or clickstream data. An IP address is a number associated with
              the service through which you access the Internet. Standing alone,
              your IP address is not personally identifiable. At times, we also
              use IP addresses for purposes of system administration and to
              report aggregate information to our advertisers and sponsors. We
              may combine this information with personally identifiable
              information. Click-stream data is information collected by our
              computers when you request web pages from the website. Clickstream
              data may include such information as the page served, the time,
              the source of the request, the type of browser making the request,
              the preceding page view and other such non-personal information.
              When analyzed, this data helps us analyze how visitors arrive at
              the website, what type of content is most popular, what type of
              visitors in the aggregate are interested in particular kinds of
              content and advertising, and the like.
            </li>
            <li>
              By using our website, you (the visitor) agree to allow third
              parties to process your IP address, in order to determine your
              location for the purpose of currency conversion. You also agree to
              have that currency stored in a session cookie in your browser (a
              temporary cookie which gets automatically removed when you close
              your browser). We do this in order for the selected currency to
              remain selected and consistent when browsing our website so that
              the prices can convert to your (the visitor) local currency.
            </li>
          </ul>
          <ul>
            <h2 className="privacy-title">
              Disclosure of Personal Information
            </h2>
            <li>
              We do not disclose your personal Information to any third parties
              other than as may be required by us, savvydeal.ai’s affiliates,
              partners, trusted business networks, in compliance with our
              Privacy Policy for the purpose of moderating the content of the
              website, enhancing your user experience, providing you
              personalized content and to enable us and our partners to provide
              you with targeted information which may be of benefit to you.
            </li>
            <li>
              Your personal information is contained behind secured networks and
              is only accessible by a limited number of persons who have special
              access rights to such systems, and are required to keep the
              information confidential. In addition, all sensitive/credit
              information you supply is encrypted via Secure Socket Layer (SSL)
              technology.
            </li>
            <li>
              To enhance customer experience and to provide focused support, we
              may share generic aggregated demographic information which may
              include your information collected by us but not linked to any
              personally identifiable information regarding visitors and users
              with our business partners, trusted affiliates and advertisers for
              the purposes outlined above.
            </li>
            <li>
              At times we are required by law or litigation to disclose personal
              information about the users. We may also disclose information
              about the user if we determine that disclosure of information is
              necessary for national security, law enforcement, or other issues
              of public importance.
            </li>
            <li>
              <div className="privacy-item">
                We use our best efforts to use information in aggregate form (so
                that no individual user is identified) for the following
                purposes:
              </div>
              <ul>
                <li className="nested-list">To build up marketing profiles;</li>
                <li className="nested-list">
                  To aid strategic development, data collection and business
                  analytics;
                </li>
                <li className="nested-list">
                  To manage our relationship with advertisers and partners;
                </li>
                <li className="nested-list">
                  To audit usage of the website and our mobile application;
                </li>
                <li className="nested-list">
                  To enhance user experience in relation to the website
                  (collectively, “permitted use”).
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <h2 className="privacy-title">Cookies</h2>
            <li>
              Whenever you access the website, we may place "cookies" on your
              hard drive for record-keeping purposes to enhance your experience
              or sometimes to personalize your experience. Cookies are small
              text files that are placed on your computer’s hard drive by the
              websites you visit. Cookies help us to identify information
              relating your activities and to retain information relating to
              your preferences and history on the website.
            </li>
            <li>
              AD targeting cookies: we and/or our service providers may use
              advertising cookies to deliver ads that are more relevant to you
              and your interests.
            </li>
            <li>
              You may choose to disable cookies by turning off cookie feature on
              the web browser. However, by disabling this feature, some parts of
              the website may not function properly. This may prevent you from
              taking full advantage of the website.
            </li>
          </ul>
          <ul>
            <h2 className="privacy-title">Confidentiality</h2>
            <li>
              <div>
                Except as otherwise provided in this privacy policy, we will
                keep your personal information private and will not share it
                with third parties, unless we believe in good faith that
                disclosure of your personal information or any other information
                we collect about you is necessary for permitted use or to:
              </div>
              <ul>
                <li className="nested-list">
                  Comply with a court order or other legal process;
                </li>
                <li className="nested-list">
                  Protect the rights, property or safety of savvydeal.ai or
                  another party
                </li>
                <li className="nested-list">
                  Enforce the policy or any agreement, including Terms of
                  Service;
                </li>
                <li className="nested-list">
                  Respond to claims that any posting or other content violates
                  the rights of third-parties.
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <h2 className="privacy-title">Security</h2>
            <li>
              The security of your personal information is important to us. We
              follow generally accepted industry standards to protect the
              personal Information submitted to us, both during transmission and
              once we receive it.
            </li>
            <li>
              Although we make best possible efforts to store personal
              information in a secure operating environment that is not open to
              the public, you should understand that there is no such thing as
              complete security, and we do not guarantee that there will be no
              unintended disclosures of your personal information. If we become
              aware that your personal information has been disclosed in a
              manner not in accordance with this privacy policy, we will use
              reasonable efforts to notify you of the nature and extent of such
              disclosure (to the extent we know that information) as soon as
              reasonably possible and as permitted by law.
            </li>
          </ul>
          <ul>
            <h2 className="privacy-title">
              Updates and Changes to Privacy Policy
            </h2>
            <li>
              We reserve the right, at any time, to add to, change, update, or
              modify this privacy policy so please review it frequently. In all
              cases, use of information we collect is subject to the privacy
              policy in effect at the time such information is collected. You
              hereby acknowledge and agree that it is your responsibility to
              review this privacy policy periodically and become aware of the
              modifications. If you disagree to any of the changes to the
              privacy policy, you shall refrain from using or accessing the
              website. Your continued use of the website, following the posting
              of the revised policy shall indicate your acceptance and
              acknowledgement of the changes and you will be bound by it.
            </li>
          </ul>
          <ul>
            <h2 className="privacy-title">
              Updates and Changes to Your Personal Information
            </h2>
            <li>
              You have a right to correct any errors in your personal
              information available with us. you may request us in writing that
              we cease to use your personal information. We will retain your
              information for as long as your account is active or as needed to
              provide you services. We will retain and use your information as
              necessary to comply with our legal obligations, resolve disputes,
              and enforce our agreements.
            </li>
          </ul>
          <ul>
            <h2 className="privacy-title">International Users</h2>
            <li>
              Please note that the website is directed towards users who reside
              in the United States. By using the website, you consent to the
              collection, storage, processing, and transfer of your information
              in and to the United States, or other countries and territories,
              pursuant to the laws of the United States. While some of these
              countries may not offer the same level of privacy protection as
              your own, We commit to uphold the privacy protections as explained
              in this privacy policy.
            </li>
          </ul>
          <ul>
            <h2 className="privacy-title">Contact Us</h2>
            <li>
              You may write to us at support@savvydeal.ai for any privacy
              concerns and requests relating to this terms of service and
              privacy policy.
            </li>
          </ul>
        </div>
        <MobileFooter />
      </div>
      {isCategoryOverlayOpen && (
        <CategoryOverlay
          isOverlayOpen={isCategoryOverlayOpen}
          toggleOverlay={toggleCategoryOverlay}
        />
      )}
      {isLoginModalOverlayOpen && (
        <MobileLoginModalOverlay
          isOverlayOpen={isLoginModalOverlayOpen}
          toggleOverlay={toggleLoginModalOverlay}
        />
      )}
    </div>
  );
};
