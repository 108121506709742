import axios from "axios";
import store from "./store";
import * as types from "./reducers/users/actionTypes";

const host =
  process.env.REACT_APP_STAGE === "dev"
    ? "http://localhost:7080"
    : "https://app.kupon.ai";

const OPEN_AI_API_KEY = "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";

axios.defaults.baseURL = `${host}/api/v1`;
const mainInstance = axios.create();

const getContentType = (httpMethod) =>
  httpMethod === "patch"
    ? "application/json-patch+json;charset=UTF-8"
    : "application/json;charset=UTF-8";

mainInstance.interceptors.request.use(
  (config) => {
    const { users: { listing: { token } = {} } = {} } = store.getState();
    // List of URLs that require Authorization header
    const urlsRequiringAuth = [
      '/auth/userProducts',
      '/api/v1/auth/userProducts/favorite',
      '/api/v1/auth/userProducts/userProducts',
    ];
    const urlRequiredAuth = urlsRequiringAuth.some((url) => config.url.includes(url));
    if (token && urlRequiredAuth) {
      config.headers = {
        "Content-Type": getContentType(config.method),
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      };
    } else {
      config.headers = {
        "Content-Type": getContentType(config.method),
        "Access-Control-Allow-Origin": "*",
      };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

mainInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const {
        response: { status }
      } = error;
      const handledError = { ...error, handled: true };
      switch (status) {
        case 401:
          store.dispatch({
            type: types.USERS_LOGOUT_USER_SUCCESS
          })
          return Promise.reject('Unauthorized user');
        case 403:
          store.dispatch({
            type: types.USERS_LOGOUT_USER_SUCCESS
          })
          return Promise.reject(handledError);
        case 409:
          return Promise.reject(handledError);
        case 418:
          window.history.pushState({}, '', '/maintenance');
          return Promise.reject('Maintenance mode');
        case 500:
          return Promise.reject(handledError);
        case 502:
        // fall-through
        // eslint-disable-next-line no-fallthrough
        case 503:
        // fall-through
        // eslint-disable-next-line no-fallthrough
        case 504:
          return Promise.reject(handledError);
        default:
          return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export const checkEmailExists = (email) => {
  return mainInstance.post("/users/checkEmail", { email });
};

export const loginUser = ({ data }) => {
  return mainInstance.post("/users/login", data);
};

export const addUser = ({ data }) => {
  return mainInstance.post("/users/add", data);
};

export const autoCreateUser = ({ user }) => {
  return mainInstance.post("/users/autoCreate", user);
};

export const checkUserFavoriteProduct = ({ userId, productSku }) => {
  return mainInstance.get(
    `/auth/userProducts/favorite?${queryStringify({ userId, productSku })}`,
  );
};

export const addUserFavoriteProduct = ({ userId, productSku }) => {
  return mainInstance.post(
    `/auth/userProducts?${queryStringify({ userId, productSku })}`,
  );
};

export const deleteUserFavoriteProduct = ({ userId, productSku }) => {
  return mainInstance.delete(
    `/auth/userProducts?${queryStringify({ userId, productSku })}`,
  );
};

export const getAllUserFavoriteProducts = ({ userId }) => {
  return mainInstance.get(`/auth/userProducts/products?${queryStringify({ userId })}`);
}

export const searchProducts = ({ query = "*", pageNum = 0, pageSize = 20 }) => {
  return mainInstance.get(
    `/products/search?${queryStringify({ query, pageNum, pageSize })}`,
  );
};

export const getProductBySku = ({ sku, locale = "en-US" }) => {
  return mainInstance.get(`/products/product/${sku}?locale=${locale}`);
};

export const getProductByCategoryWithPageSize = ({
  category = "",
  pageNum = 0,
  pageSize = 20,
  locale = "en-US",
}) => {
  return mainInstance.get(
    `/products?category=${category}&pageNum=${pageNum}&pageSize=${pageSize}&locale=${locale}`,
  );
};

export const getProductsByCategoriesAndFilterAndPage = ({
  categories = [],
  pageNum = 0,
  pageSize = 20,
  locale = "en-US",
  minPrice = 0,
  maxPrice = 800,
  minDiscount = 10,
  minRating = 1,
  sortOrder = null,
}) => {
  return mainInstance.get(
    `/products?${queryStringify({
      categories,
      pageNum,
      pageSize,
      locale,
      minPrice,
      maxPrice,
      minDiscount,
      minRating,
      sortOrder,
    })}`,
  );
};

export const getRecommendProductsByCategory = ({
  category = "",
  sku = "",
  pageSize = 3,
  locale = "en-US",
}) => {
  return mainInstance.get(
    `/products/recommend?${queryStringify({
      category,
      sku,
      pageSize,
      locale,
    })}`,
  );
};

export const getTrendingProducts = ({
  pageNum = 0,
  pageSize = 20,
  locale = "en-US",
}) => {
  return mainInstance.get(
    `/products/trending?${queryStringify({ pageNum, pageSize, locale })}`,
  );
};

export const addUserTracking = ({ userId, sku, category, type }) => {
  return mainInstance.get(
    `/usertracking?${queryStringify({ userId, sku, category, type })}`,
  );
};

export const postSendMessageRequest = ({ data }) => {
  return mainInstance.post("/email", data);
};

export const postMessagesToChatGPT = ({ messages }) => {
  return axios.post(
    "https://api.openai.com/v1/chat/completions",
    { model: "gpt-3.5-turbo", messages },
    {
      headers: {
        Authorization: `Bearer ${OPEN_AI_API_KEY}`,
        ContentType: "application/json",
      },
    },
  );
};

function queryStringify(params) {
  if (typeof params !== "object") {
    // TODO: This should return empty string
    return null;
  }
  return Object.keys(params)
    .filter((key) => params[key] !== undefined && params[key] !== null)
    .map((key) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    })
    .join("&");
}
