export const PRODUCTS_SET_ACTIVE_PRODUCT_CATEGORY_ID =
  "products/SET_ACTIVE_PRODUCT_CATEGORY_ID";
export const PRODUCTS_SET_SEARCH_QUERY = "products/SET_SEARCH_QUERY";
export const PRODUCTS_SEARCH_PRODUCTS = "products/SEARCH_PRODUCTS";
export const PRODUCTS_GET_PRODUCT_BY_SKU = "products/GET_PRODUCT_BY_SKU";
export const PRODUCTS_GET_PRODUCTS_BY_CATEGORY =
  "products/GET_PRODUCTS_BY_CATEGORY";
export const PRODUCTS_GET_PRODUCTS_BY_CATEGORIES =
  "products/GET_PRODUCTS_BY_CATEGORIES";
export const PRODUCTS_GET_PRODUCTS_LOADING = "products/GET_PRODUCTS_LOADING";
export const PRODUCTS_GET_RECOMMEND_PRODUCTS_BY_CATEGORY =
  "products/GET_RECOMMEND_PRODUCTS_BY_CATEGORY";
export const PRODUCTS_GET_TRENDING_PRODUCTS = "products/GET_TRENDING_PRODUCTS";
export const PRODUCTS_RESET_PRODUCTS_BY_CATEGORY =
  "products/RESET_PRODUCTS_BY_CATEGORY";
