import { useMutation, useQuery } from "@tanstack/react-query";
import * as api from "../../../api";

export const useUserLogin = (options = {}) =>
  useMutation({
    mutationKey: ["useUserLogin"],
    mutationFn: ({ data }) => api.loginUser({ data }).then((res) => res.data),
    ...options,
  });

export const useCheckUserFavoriteProductBySku = (
  { userId, productSku },
  options = {},
) =>
  useQuery({
    queryKey: ["checkUserFavoriteProductBySku", { userId, productSku }],
    queryFn: () =>  api.checkUserFavoriteProduct({ userId, productSku }).then(res => res?.data),
    ...options,
  });

export const useAddUserFavoriteProductBySku = (options = {}) =>
  useMutation({
    mutationKey: ["useAddUserFavoriteProductBySku"],
    mutationFn: ({ userId, productSku }) =>
      api.addUserFavoriteProduct({ userId, productSku }),
    ...options,
  });

export const useDeleteUserFavoriteProductBySku = (options = {}) =>
  useMutation({
    mutationKey: ["useDeleteUserFavoriteProductBySku"],
    mutationFn: ({ userId, productSku }) =>
      api.deleteUserFavoriteProduct({ userId, productSku }),
    ...options,
  });
