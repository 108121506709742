import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getCookie } from "./utils/cookies";
import { USER_FIRST_NAME, USER_ID, USER_TOKEN } from "./utils/constants";
import { autoLoginUser } from "./reducers/users/actions";
import { MainPage } from "./components/redesign/MainPage";
import { ProductPage } from "./components/redesign/ProductPage";
import { ProductGroupPage } from "./components/redesign/ProductGroupPage";
import { UserFavoriteProductsPage } from './components/redesign/UserFavoriteProductsPage';
import { NotFoundPage } from "./components/NotFoundPage";
import { TermsOfServicePage } from "./components/TermsOfServicePage";
import { AboutPage } from "./components/AboutPage";
import { PrivacyPolicyPage } from "./components/PrivacyPolicyPage";
import ContactUsPage from "./components/ContactUsPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { SearchProductsPage } from "./components/redesign/SearchProductsPage";
import "@smastrom/react-rating/style.css";
import "./App.scss";

const App = () => {
  const dispatch = useDispatch();
  const token = getCookie({ name: USER_TOKEN });
  useEffect(() => {
    if (token) {
      const id = getCookie({ name: USER_ID });
      const firstName = getCookie({ name: USER_FIRST_NAME });
      dispatch(autoLoginUser({ id, firstName, token }));
    }
  }, [dispatch, token]);

  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/products" element={<ProductGroupPage />} />
      <Route path="/favorites" element={<UserFavoriteProductsPage />} />
      <Route path="/search" element={<SearchProductsPage />} />
      <Route path="/product/:sku" element={<ProductPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/privacy" element={<PrivacyPolicyPage />} />
      <Route path="/termsofservice" element={<TermsOfServicePage />} />
      <Route path="/contact" element={<ContactUsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
