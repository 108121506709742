import { useQuery } from "@tanstack/react-query";
import * as api from "../../../api";

export const useGetProductsByCategoriesAndFilters = ({
  categories,
  pageNum,
  pageSize,
  locale,
  minDiscount,
  minRating,
  sortOrder,
  minPrice,
  maxPrice,
}) => {
  const { data, isLoading, error } = useQuery({
    queryKey: [],
    queryFn: () =>
      api
        .getProductsByCategoriesAndFilterAndPage({
          categories,
          pageNum,
          pageSize,
          locale,
          minDiscount,
          minRating,
          sortOrder,
          minPrice,
          maxPrice,
        })
        .then((response) => response.data),
  });
  return { products: data, loading: isLoading, error };
};

export const useGetAllUserFavoriteProducts = ({ userId }, options = {}) =>
  useQuery({
    queryKey: ['useGetAllUserFavoriteProducts', { userId }],
    queryFn: () => api.getAllUserFavoriteProducts({ userId }).then(res => res?.data),
    ...options
  });