import React, { useEffect, useState } from 'react';
import { MobileHeader } from '../../header/MobileHeader';
import { MobileProductList } from './MobileProductList';
import { MobileFooter } from '../../footer/MobileFooter';
import { CategoryOverlay } from '../../header/CategoryOverlay';
import MobileLoginModalOverlay from '../../header/MobileLoginModalOverlay';
import { useSelector } from 'react-redux';
import { selectListingUser } from '../../../reducers/users/listingSelectors';
import { useGetAllUserFavoriteProducts } from '../hook/productQueries';
import { useNavigate } from 'react-router-dom';

const MobileUserFavoriteProductsPage = () => {
  const { id: userId, token } = useSelector(selectListingUser);
  const navigate = useNavigate();
  const [isCategoryOverlayOpen, setIsCategoryOverlayOpen] = useState(false);
  const [isLoginModalOverlayOpen, setIsLoginOverlayOpen] = useState(false);
  const toggleCategoryOverlay = () => {
    setIsCategoryOverlayOpen(!isCategoryOverlayOpen); // Toggle overlay visibility
  };
  
  const toggleLoginModalOverlay = () => {
    setIsLoginOverlayOpen(!isLoginModalOverlayOpen);
  };
  
  const { data: products = [] } = useGetAllUserFavoriteProducts({ userId }, { enabled: !!userId });
  
  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [navigate, token]);
  return (
    <div className="App">
      <div className="mobile-products-group-container">
        <MobileHeader
          toggleCategoryOverlay={toggleCategoryOverlay}
          toggleLoginOverlay={toggleLoginModalOverlay}
        />
        <MobileProductList
          products={products}
          totalResults={products.length}
          enablePagination={false}
        />
        <MobileFooter/>
      </div>
      {isCategoryOverlayOpen && (
        <CategoryOverlay
          isOverlayOpen={isCategoryOverlayOpen}
          toggleOverlay={toggleCategoryOverlay}
        />
      )}
      {isLoginModalOverlayOpen && (
        <MobileLoginModalOverlay
          isOverlayOpen={isLoginModalOverlayOpen}
          toggleOverlay={toggleLoginModalOverlay}
        />
      )}
    </div>
  )
};

export default MobileUserFavoriteProductsPage;