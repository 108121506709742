import * as types from "./actionTypes";
import * as api from "../../api";
import { selectFormattedChatMessages } from "./createSelectors";

export const addNewMessage = (message) => {
  return { type: types.CHATS_ADD_NEW_MESSAGE, payload: { message } };
};

export const sendMessagesToChatGPT = ({ url }) => {
  const systemMessage = {
    role: "system",
    content: `Currently I am the online shopper at https://savvydeal.ai/ who is looking product ${url} and could be interested to buy. Don't say with the Amazon link provided. Given me more highlight of this product, why I should buy it and attract me more to buy.`,
  };

  return (dispatch, getState) => {
    dispatch({ type: types.CHATS_START_SEND_MESSAGE_TO_CHATGPT });
    const messages = selectFormattedChatMessages(getState());
    api
      .postMessagesToChatGPT({ messages: [systemMessage, ...messages] })
      .then(({ data }) => {
        dispatch({
          type: types.CHATS_RECEIVE_MESSAGE_FROM_CHATGPT,
          payload: { content: data.choices[0].message.content },
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };
};
