import React, { useEffect, useState } from "react";
import Navbar from "./header/NavBar";
import { Footer } from "./footer/Footer";
import "../App.scss";
import "./aboutpage.scss";
import { useIsMobile } from "./redesign/hook/layoutHooks";
import { MobileHeader } from "./header/MobileHeader";
import { MobileFooter } from "./footer/MobileFooter";
import { CategoryOverlay } from "./header/CategoryOverlay";
import MobileLoginModalOverlay from "./header/MobileLoginModalOverlay";

const DesktopAboutPage = () => (
  <div className="App">
    <Navbar />
    <div className="about-page-container">
      <h1>About SavvyDeal</h1>
      <div className="about-content">
        SavvyDeal is an online marketplace that connects consumers with millions
        of discounted items from sellers, manufacturers and brands around the
        world with the mission to empower them to live their best lives.
        SavvyDeal is committed to offering the most affordable quality products
        to enable consumers and sellers to fulfill their dreams in an inclusive
        environment. SavvyDeal was founded in Sunnyvale, California in 2023.
      </div>
    </div>
    <Footer />
  </div>
);

const MobileAboutPage = () => {
  const [isCategoryOverlayOpen, setIsCategoryOverlayOpen] = useState(false);
  const [isLoginModalOverlayOpen, setIsLoginOverlayOpen] = useState(false);

  const toggleCategoryOverlay = () => {
    setIsCategoryOverlayOpen(!isCategoryOverlayOpen); // Toggle overlay visibility
  };

  const toggleLoginModalOverlay = () => {
    setIsLoginOverlayOpen(!isLoginModalOverlayOpen);
  };

  // Toggle body class to disable or enable scrolling
  if (isCategoryOverlayOpen || isLoginModalOverlayOpen) {
    document.body.classList.add("no-scroll");
  } else {
    document.body.classList.remove("no-scroll");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <div className="App">
      <MobileHeader
        toggleCategoryOverlay={toggleCategoryOverlay}
        toggleLoginOverlay={toggleLoginModalOverlay}
      />
      <div className="mobile-about-page-container">
        <h1>About SavvyDeal</h1>
        <div className="mobile-about-content">
          SavvyDeal is an online marketplace that connects consumers with
          millions of discounted items from sellers, manufacturers and brands
          around the world with the mission to empower them to live their best
          lives. SavvyDeal is committed to offering the most affordable quality
          products to enable consumers and sellers to fulfill their dreams in an
          inclusive environment. SavvyDeal was founded in Sunnyvale, California
          in 2023.
        </div>
      </div>
      <MobileFooter />
      {isCategoryOverlayOpen && (
        <CategoryOverlay
          isOverlayOpen={isCategoryOverlayOpen}
          toggleOverlay={toggleCategoryOverlay}
        />
      )}
      {isLoginModalOverlayOpen && (
        <MobileLoginModalOverlay
          isOverlayOpen={isLoginModalOverlayOpen}
          toggleOverlay={toggleLoginModalOverlay}
        />
      )}
    </div>
  );
};

export const AboutPage = () => {
  const isMobile = useIsMobile();
  return isMobile ? <MobileAboutPage /> : <DesktopAboutPage />;
};
